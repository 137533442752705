html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', serif;
  scroll-behavior: smooth;
  font-style: normal;
}

.title {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #2b2b2b;
}

.subtitle {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: #2b2b2b;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #9d9d9d;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 0.25rem;
  label {
    font-weight: bold;
  }
}

@mixin center-page() {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  text-align: center;
}
